import { memo, useEffect, useMemo, useRef, useState } from "react";
import { format } from "date-fns";
import ReCAPTCHA from "react-google-recaptcha";
import SignatureCanvas from "react-signature-canvas";
import Select from "react-select";
import clsx from "clsx";

const whiteList = [
  "5f7b205e1a883c18ab5a6d7b",
  "5f7b1ca326464912dd20dac1",
  "5f7b21be1a883c18ab5a6d81",
  "5f7b20b21a883c18ab5a6d7d",
  "5f7b22291a883c18ab5a6d82",
  "5f7b20dc1a883c18ab5a6d7e",
  "5f7b208e1a883c18ab5a6d7c",
  "655f13bdb8c4176788f16499",
];

const Checkbox = memo(({ label, disabled, ...props }) => (
  <div
    className={clsx("checkbox-container", {
      "checkbox-container-disabled": disabled,
    })}
  >
    <label className="checkbox-label">
      <input
        {...props}
        disabled={disabled}
        type="checkbox"
        className="checkbox-input"
      />
      <div className="checkbox-box" />
      <p className="checkbox-text">{label}</p>
    </label>
  </div>
));

Checkbox.displayName = "Checkbox";

const App = () => {
  const [values, setValues] = useState({
    company: "",
    siret: "",
    full_name: "",
    function: "",
    signature: "",
    recaptcha: "",
    additional_message: "",
    options: [],
    agency: null,
    helpdesk_total: "",
    front_end_total: "",
    data_analyst_total: "",
  });
  const [errors, setErrors] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [agencies, setAgencies] = useState([]);
  const [isAgenciesLoading, setIsAgenciesLoading] = useState(true);
  const date = useMemo(() => format(new Date(), "dd/MM/yyyy"), []);
  const signatureRef = useRef(null);
  const recaptchaRef = useRef(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/global/agencies`)
      .then((res) => res.json())
      .then(({ status, data }) => {
        setIsAgenciesLoading(false);

        if (status === 200) {
          let newAgencies = data.filter((item) => whiteList.includes(item._id));

          const toMerge = newAgencies.filter((item) =>
            ["5f7b208e1a883c18ab5a6d7c", "5f7b205e1a883c18ab5a6d7b"].includes(
              item._id
            )
          );

          if (toMerge.length > 1) {
            newAgencies = newAgencies.filter(
              (item) => !toMerge.find((mergeItem) => mergeItem._id === item._id)
            );

            const [ids, cities] = toMerge.reduce(
              (prev, curr) => {
                prev[0].push(curr._id);
                prev[1].push(curr.city);

                return prev;
              },
              [[], []]
            );

            newAgencies.push({
              _id: `merge_${ids.join("_")}`,
              city: `${cities
                .sort((a, b) => a.localeCompare(b, "fr"))
                .join(" / ")}`,
              postal_code: "44600",
            });
          }

          setAgencies(
            newAgencies.sort(
              (a, b) => Number(a.postal_code) - Number(b.postal_code)
            )
          );
        }
      });
  }, []);

  useEffect(() => {
    if (
      [
        "5f7b205e1a883c18ab5a6d7b",
        "5f7b208e1a883c18ab5a6d7c",
        "5f7b20dc1a883c18ab5a6d7e",
        "5f7b22291a883c18ab5a6d82",
        "5f7b21be1a883c18ab5a6d81",
        "5f7b1ca326464912dd20dac1",
      ].some(
        (id) => values.agency?._id === id || values.agency?._id.includes(id)
      )
    ) {
      setValues((values) => {
        let newOptions = [...values.options];

        newOptions = newOptions.filter((item) => !["front-end"].includes(item));

        values.front_end_total = "";

        return { ...values, options: newOptions };
      });
    }

    if (
      ["5f7b20b21a883c18ab5a6d7d", "655f13bdb8c4176788f16499"].some(
        (id) => values.agency?._id === id || values.agency?._id.includes(id)
      )
    ) {
      setValues((values) => {
        let newOptions = [...values.options];

        newOptions = newOptions.filter(
          (item) => !["helpdesk", "data-analyst"].includes(item)
        );

        values.helpdesk_total = "";
        values.data_analyst_total = "";

        return { ...values, options: newOptions };
      });
    }
  }, [values.agency]);

  const onCheck = (event, value) => {
    const newValue = [...values.options];

    if (event.target.checked) {
      newValue.push(value);

      return setValues((rest) => ({ ...rest, options: newValue }));
    } else {
      const index = newValue.indexOf(value);

      if (index !== -1) {
        newValue.splice(index, 1);
      }

      return setValues((rest) => ({ ...rest, options: newValue }));
    }
  };

  const onChange = (key, value) => {
    return setValues((rest) => ({ ...rest, [key]: value }));
  };

  const onSign = () => {
    const signature = signatureRef?.current
      ?.getTrimmedCanvas()
      .toDataURL("image/png");

    return onChange("signature", signature);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setIsProcessing(true);

    if (values?.success) {
      setValues((rest) => ({ ...rest, success: false }));
    }

    const agency = values?.agency
      ? `${values?.agency?.postal_code.slice(0, 2)} - ${values?.agency?.city}`
      : null;

    fetch(`${process.env.REACT_APP_API_ENDPOINT}/recruitment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...values, agency }),
    })
      .then((res) => res.json())
      .then(({ status, message, data }) => {
        if (status === 200) {
          signatureRef.current.clear();

          setValues({
            company: "",
            siret: "",
            full_name: "",
            function: "",
            signature: "",
            recaptcha: "",
            additional_message: "",
            options: [],
            agency: null,
            helpdesk_total: "",
            front_end_total: "",
            data_analyst_total: "",
            success: true,
          });

          setErrors({});
        } else {
          setErrors(data);
        }
      })
      .finally(() => {
        recaptchaRef.current.reset();
        setIsProcessing(false);
      });
  };

  return (
    <main className="container">
      <div className="image-container" />
      <h1 className="title">ATTESTATION DE SOUTIEN ENTREPRISE</h1>
      <form noValidate onSubmit={onSubmit} className="form">
        <p className="description">
          Suite à vos retours lors d'un premier questionnaire, nous nous sommes
          focalisés sur les parcours les plus demandés (merci à vous !).
        </p>
        <p className="description">
          Nous vous invitons à :
          <ul className="list">
            <li>
              confirmer votre intention, qui a peut-être évoluée (même si vous
              aviez déjà répondu)
            </li>
            <li>
              pour les entreprises s'étant positionnées sur d'autres
              thématiques, nous faire part de votre intérêt pour les parcours
              retenus dans cette liste
            </li>
          </ul>
        </p>
        <p className="description">
          L'objectif de cette formation co-construite "<b>Arinfo x Simplon</b>"
          est de monter en compétences des jeunes (et moins jeunes) sur 3 mois,
          avec possibilité d'embauche à mi-2024 à temps plein ou bien au format
          alternance (éligible aux aides ✔️). Merci pour votre temps 😌
        </p>
        <div className="form-row">
          <div className="form-field">
            <label>Lieu de formation :</label>
            <Select
              placeholder="Sélectionner dans la liste"
              options={agencies}
              isLoading={isAgenciesLoading}
              getOptionLabel={(record) => {
                const postalCode = record.postal_code.slice(0, 2);
                return `${postalCode} - ${record.city}`;
              }}
              getOptionValue={(record) => record._id}
              noOptionsMessage={() => "Aucune option"}
              name="agency"
              value={values.agency}
              isDisabled={isAgenciesLoading || isProcessing}
              onChange={(value) => onChange("agency", value)}
              styles={{
                control: (base) => ({
                  ...base,
                  borderColor: errors?.agency ? "#ff3548" : "#e8e2e6",
                }),
                menuList: (base) => ({
                  ...base,
                  maxHeight: 175,
                  overflowY: "scroll",
                }),
              }}
            />
            {errors?.agency && (
              <small className="form-error">{errors?.agency}</small>
            )}
          </div>
          <div className="form-field form-field-filler" />
        </div>
        {values?.agency !== null && (
          <>
            {[
              "5f7b205e1a883c18ab5a6d7b",
              "5f7b208e1a883c18ab5a6d7c",
              "5f7b20dc1a883c18ab5a6d7e",
              "5f7b22291a883c18ab5a6d82",
              "5f7b21be1a883c18ab5a6d81",
              "5f7b1ca326464912dd20dac1",
            ].some(
              (id) =>
                values.agency?._id === id || values.agency?._id.includes(id)
            ) && (
              <div className="form-field">
                <Checkbox
                  label={
                    <>
                      <b>Technicien Helpdesk</b>{" "}
                      <i>
                        (voir{" "}
                        <a
                          href="/fichiers/1_Technicien_Helpdesk-Programme_de_formation.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                          download
                        >
                          programme
                        </a>{" "}
                        annexé)
                      </i>
                    </>
                  }
                  name="helpdesk"
                  checked={values.options.includes("helpdesk")}
                  onChange={(event) => onCheck(event, "helpdesk")}
                  disabled={isProcessing}
                />
                <p className="description">
                  Compétences : support technique dans un contexte commercial,
                  gestion d’incident dans un centre de services et suivi du
                  parc, assistance utilisateurs en environnement bureautique et
                  équipements numériques, résolution dysfonctionnement
                  numérique.
                </p>
                {values.options.includes("helpdesk") && (
                  <div className="form-row">
                    <div className="form-field">
                      <label className="form-label">
                        Nombre de recrutements envisagés
                      </label>
                      <input
                        name="helpdesk_total"
                        value={values?.helpdesk_total}
                        onChange={(event) => {
                          onChange(
                            "helpdesk_total",
                            !event.target.value.startsWith("0")
                              ? event.target.value.replace(/[^0-9]/g, "")
                              : ""
                          );
                        }}
                        disabled={isProcessing}
                        className={clsx("form-input", {
                          error: errors?.helpdesk_total,
                        })}
                      />
                      {errors?.helpdesk_total && (
                        <small className="form-error">
                          {errors?.helpdesk_total}
                        </small>
                      )}
                    </div>
                    <div className="form-field form-field-filler" />
                  </div>
                )}
              </div>
            )}
            {["5f7b20b21a883c18ab5a6d7d", "655f13bdb8c4176788f16499"].some(
              (id) =>
                values.agency?._id === id || values.agency?._id.includes(id)
            ) && (
              <div className="form-field">
                <Checkbox
                  label={
                    <>
                      <b>Développeur Front End</b>{" "}
                      <i>
                        (voir{" "}
                        <a
                          href="/fichiers/BZH_[Dev_Front]-Programme_de_formation.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                          download
                        >
                          programme
                        </a>{" "}
                        annexé)
                      </i>
                    </>
                  }
                  name="front-end"
                  checked={values.options.includes("front-end")}
                  onChange={(event) => onCheck(event, "front-end")}
                  disabled={isProcessing}
                />
                <p className="description">
                  Compétences : réalisation de maquette et interface utilisateur
                  web statique et adaptable, développement d’interface
                  utilisateur web dynamique, intégration et système de gestion
                  de contenu.
                </p>
                {values.options.includes("front-end") && (
                  <div className="form-row">
                    <div className="form-field">
                      <label className="form-label">
                        Nombre de recrutements envisagés
                      </label>
                      <input
                        name="front_end_total"
                        value={values?.front_end_total}
                        onChange={(event) => {
                          onChange(
                            "front_end_total",
                            !event.target.value.startsWith("0")
                              ? event.target.value.replace(/[^0-9]/g, "")
                              : ""
                          );
                        }}
                        disabled={isProcessing}
                        className={clsx("form-input", {
                          error: errors?.front_end_total,
                        })}
                      />
                      {errors?.front_end_total && (
                        <small className="form-error">
                          {errors?.front_end_total}
                        </small>
                      )}
                    </div>
                    <div className="form-field form-field-filler" />
                  </div>
                )}
              </div>
            )}
            {[
              "5f7b205e1a883c18ab5a6d7b",
              "5f7b208e1a883c18ab5a6d7c",
              "5f7b20dc1a883c18ab5a6d7e",
              "5f7b22291a883c18ab5a6d82",
              "5f7b21be1a883c18ab5a6d81",
              "5f7b1ca326464912dd20dac1",
            ].some(
              (id) =>
                values.agency?._id === id || values.agency?._id.includes(id)
            ) && (
              <div className="form-field">
                <Checkbox
                  label={
                    <>
                      <b>Data Analyst</b>{" "}
                      <i>
                        (voir{" "}
                        <a
                          href="/fichiers/3_Data_analyst-Programme_de_formation.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                          download
                        >
                          programme
                        </a>{" "}
                        annexé)
                      </i>
                    </>
                  }
                  name="data-analyst"
                  checked={values.options.includes("data-analyst")}
                  onChange={(event) => onCheck(event, "data-analyst")}
                  disabled={isProcessing}
                />
                <p className="description">
                  Compétences : qualification des données, préparation pour les
                  algo IA, conception programme IA à l’aide du machine learning,
                  réalisation de rendus visuels, mise à disposition contrôlée,
                  analyse de demande et exploitation des bases de données.
                </p>
                {values.options.includes("data-analyst") && (
                  <div className="form-row">
                    <div className="form-field">
                      <label className="form-label">
                        Nombre de recrutements envisagés
                      </label>
                      <input
                        name="data_analyst_total"
                        value={values?.data_analyst_total}
                        onChange={(event) => {
                          onChange(
                            "data_analyst_total",
                            !event.target.value.startsWith("0")
                              ? event.target.value.replace(/[^0-9]/g, "")
                              : ""
                          );
                        }}
                        disabled={isProcessing}
                        className={clsx("form-input", {
                          error: errors?.data_analyst_total,
                        })}
                      />
                      {errors?.data_analyst_total && (
                        <small className="form-error">
                          {errors?.data_analyst_total}
                        </small>
                      )}
                    </div>
                    <div className="form-field form-field-filler" />
                  </div>
                )}
              </div>
            )}
          </>
        )}
        <p className="description">
          Cette action de formation pourra nous permettre de sélectionner des
          candidats formés et opérationnels immédiatement.
        </p>
        <div className="form-row">
          <div className="form-field">
            <label className="form-label">Nom entreprise :</label>
            <input
              name="company"
              value={values.company}
              onChange={(event) => onChange("company", event.target.value)}
              disabled={isProcessing || values.agency === null}
              className={clsx("form-input", { error: errors?.company })}
            />
            {errors?.company && (
              <small className="form-error">{errors?.company}</small>
            )}
          </div>
          <div className="form-field">
            <label className="form-label">N° SIRET :</label>
            <input
              name="siret"
              value={values.siret}
              onChange={(event) => onChange("siret", event.target.value)}
              disabled={isProcessing || values.agency === null}
              className={clsx("form-input", { error: errors?.siret })}
            />
            {errors?.siret && (
              <small className="form-error">{errors?.siret}</small>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="form-field">
            <label className="form-label">Nom et prénom du signataire :</label>
            <input
              name="full_name"
              value={values.full_name}
              onChange={(event) => onChange("full_name", event.target.value)}
              disabled={isProcessing || values.agency === null}
              className={clsx("form-input", { error: errors?.full_name })}
            />
            {errors?.full_name && (
              <small className="form-error">{errors?.full_name}</small>
            )}
          </div>
          <div className="form-field">
            <label className="form-label">Fonction du signataire :</label>
            <input
              name="function"
              value={values.function}
              onChange={(event) => onChange("function", event.target.value)}
              disabled={isProcessing || values.agency === null}
              className={clsx("form-input", { error: errors?.function })}
            />
            {errors?.function && (
              <small className="form-error">{errors?.function}</small>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="form-field">
            <Checkbox
              label="Je souhaite apporter un complément d'infos"
              name="additional_message"
              checked={values.options.includes("additional_message")}
              onChange={(event) => onCheck(event, "additional_message")}
              disabled={isProcessing || values.agency === null}
            />
            {values.options.includes("additional_message") && (
              <>
                <div className="form-textarea-container">
                  <textarea
                    rows={5}
                    maxLength={500}
                    name="additional_message"
                    value={values.additional_message}
                    onChange={(event) =>
                      onChange("additional_message", event.target.value)
                    }
                    disabled={isProcessing || values.agency === null}
                    className={clsx("form-textarea", {
                      error: errors?.additional_message,
                    })}
                  />
                  <span className="form-textarea-length">
                    {values?.additional_message?.length || "0"} / 500
                  </span>
                </div>
                {errors?.additional_message && (
                  <small className="form-error">
                    {errors?.additional_message}
                  </small>
                )}
              </>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="form-field">
            <label>Signature</label>
            <div
              className={clsx("form-signature", {
                "form-signature-disabled":
                  isProcessing || values.agency === null,
              })}
            >
              <SignatureCanvas
                ref={signatureRef}
                backgroundColor="white"
                onEnd={onSign}
                canvasProps={{
                  disabled: true,
                  style: {
                    pointerEvents:
                      isProcessing || values.agency === null
                        ? "none"
                        : "inherit",
                    width: "100%",
                    height: "100%",
                    border: "1px solid #000",
                    borderRadius: "4px",
                    borderColor: isProcessing
                      ? "hsl(0, 0%, 90%)"
                      : errors?.signature
                      ? "#ff3548"
                      : "rgb(204, 204, 204)",
                  },
                }}
              />
            </div>
            {errors?.signature && (
              <small className="form-error">{errors?.signature}</small>
            )}
          </div>
          <div className="form-field form-field-filler" />
        </div>
        <div className="form-field">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={(response) => onChange("recaptcha", response)}
          />
          {errors?.recaptcha && (
            <small className="form-error">{errors?.recaptcha}</small>
          )}
        </div>
        {values?.success && (
          <div className="form-success">Attestation de soutien envoyée</div>
        )}
        <div className="form-date">Fait le {date}</div>
        <p
          className="description"
          style={{ marginTop: 10, textAlign: "center" }}
        >
          Ce formulaire ne vous engage en aucun cas à embaucher, il s’agit d’une
          simple identification des besoins futurs. Merci pour votre
          participation !
        </p>
        <button type="submit" disabled={isProcessing} className="form-button">
          {isProcessing ? "Chargement" : "Envoyer"}
        </button>
        <hr style={{ borderColor: "#f5f5fb", marginTop: 20 }} />
        <p className="form-rgpd">
          Les données recueillies par ce formulaire ont pour unique but de mener
          l'étude d'identification des besoins en recrutement 2024 et ne seront
          réutilisées que dans ce contexte.
        </p>
        <div className="links">
          <a
            href="https://arinfo.fr/mentions-legales"
            target="_blank"
            rel="nopenner noreferrer"
          >
            Mentions légales
          </a>
          <a
            href="https://arinfo.fr/conditions-generales-de-prestation-et-de-vente"
            target="_blank"
            rel="nopenner noreferrer"
          >
            CGPV
          </a>
        </div>
      </form>
    </main>
  );
};

export default App;
